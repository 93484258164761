import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './filters.js';
import Filters from '@emcasa/ui-dom/components/Filters';
import Button from '@emcasa/ui-dom/components/Filters/FilterButton';
import SliderRangeFilter from '@emcasa/ui-dom/components/Filters/SliderRangeFilter';
import ButtonGroupFilter from '@emcasa/ui-dom/components/Filters/ButtonGroupFilter';
import ButtonRangeFilter from '@emcasa/ui-dom/components/Filters/ButtonRangeFilter';
import { TagsFilter, TypesFilter, PriceFilter, MaintenanceFeeFilter, PricePerAreaFilter, AreaFilter, RoomsFilter, SuitesFilter, GarageSpotsFilter, GarageTypesFilter, ConstructionYearFilter } from '@emcasa/ui-dom/components/Filters/ListingFilters';
import View from '@emcasa/ui-dom/components/View';
import tags from './tags.json';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "filters"
    }}>{`Filters`}</h1>
    <View height="150px" mdxType="View">
  <Filters onSubmit={console.log} initialValues={{
        slider: {
          min: 0,
          max: 1
        }
      }} mdxType="Filters">
    <SliderRangeFilter name="slider" label="Slider range" range={[0, 1]} mdxType="SliderRangeFilter" />
    <ButtonGroupFilter name="buttons" label="Button group" mdxType="ButtonGroupFilter">
      <Button value={0} mdxType="Button">0</Button>
      <Button value={1} mdxType="Button">1</Button>
      <Button value={2} mdxType="Button">2</Button>
      <Button value={3} mdxType="Button">3</Button>
    </ButtonGroupFilter>
    <ButtonRangeFilter name="buttonRange" label="Button range" range={[1, 5]} formatLast={() => '+'} mdxType="ButtonRangeFilter" />
  </Filters>
    </View>
    <h2 {...{
      "id": "listing-filters"
    }}>{`Listing filters`}</h2>
    <View height="150px" mdxType="View">
  <Filters onSubmit={console.log} initialValues={{
        area: AreaFilter.initialValue,
        price: PriceFilter.initialValue,
        pricePerArea: PricePerAreaFilter.initialValue,
        constructionYear: ConstructionYearFilter.initialValue
      }} mdxType="Filters">
    <TagsFilter options={tags} onChangeText={console.log} placeholder="Search tags" mdxType="TagsFilter" />
    <TypesFilter name="types" mdxType="TypesFilter" />
    <PriceFilter name="price" mdxType="PriceFilter" />
    <MaintenanceFeeFilter name="maintenanceFee" mdxType="MaintenanceFeeFilter" />
    <PricePerAreaFilter name="pricePerArea" mdxType="PricePerAreaFilter" />
    <AreaFilter name="area" mdxType="AreaFilter" />
    <RoomsFilter name="rooms" mdxType="RoomsFilter" />
    <SuitesFilter name="suites" mdxType="SuitesFilter" />
    <GarageSpotsFilter name="garageSpots" mdxType="GarageSpotsFilter" />
    <GarageTypesFilter name="garageTypes" mdxType="GarageTypesFilter" />
    <ConstructionYearFilter name="constructionYear" mdxType="ConstructionYearFilter" />
  </Filters>
    </View>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      